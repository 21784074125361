<template>
  <v-form
    :value="true"
    @click:outside="close"
    scrollable
    :persistent="processing"
  >
    <v-card>
      <v-card-title>
        {{ $t('createGiftcard') }}
      </v-card-title>
      <v-stepper
        v-model="e1"
        v-if="!completed"
      >
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
          >
            {{ $t('item') }}
          </v-stepper-step>
  
          <v-divider />
  
          <v-stepper-step
            :complete="e1 > 2"
            step="2"
          >
            {{ $t('customer') }}
          </v-stepper-step>
  
          <v-divider />
  
          <v-stepper-step
            :complete="e1 > 3"
            step="3"
          >
            {{ $t('validity') }}
          </v-stepper-step>
  
          <v-divider />
  
          <v-stepper-step step="4">
            {{ $t('distribution') }}
          </v-stepper-step>
        </v-stepper-header>
  
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-select
              class="mt-2"
              :label="$t('howManyGiftcards?')"
              outlined
              v-model="quantity"
              :items="quantities"
            />
            <v-select
              :label="$t('item')"
              outlined
              v-model="selectedItem"
              :items="giftcardItems"
              item-value="uuid"
              item-text="title"
            />
            <v-text-field
              outlined
              :label="$t('amount')"
              v-model="selectedAmount"
            />
  
            <v-btn
              color="primary"
              @click="e1 = 2"
              :disabled="!canProgressToStep2"
            >
              {{ $t('continue') }}
            </v-btn>
          </v-stepper-content>
  
          <v-stepper-content step="2">
            <v-text-field
              class="mt-2"
              :label="$t('name')"
              v-model="customerName"
              outlined
              :disabled="customerNameFixed"
            />
            <v-text-field
              :label="$t('email')"
              v-model="customerEmail"
              outlined
              :disabled="customerEmailFixed"
            />
            <v-text-field
              :label="$t('phone')"
              v-model="customerPhone"
              outlined
              :disabled="customerPhoneFixed"
            />
  
            <v-btn
              color="primary"
              @click="e1 = 3"
              :disabled="!canProgressToStep3"
            >
              {{ $t('continue') }}
            </v-btn>
  
            <v-btn
              text
              @click="e1 = 1"
            >
              {{ $t('back') }}
            </v-btn>
          </v-stepper-content>
  
          <v-stepper-content step="3">
            <v-select
              class="mt-2"
              :label="$t('expirationMonth')"
              outlined
              :items="months"
              item-value="key"
              item-text="value"
              v-model="expirationMonth"
            />
            <v-select
              :label="$t('expirationYear')"
              outlined
              :items="years"
              v-model="expirationYear"
            />
            <v-btn
              color="primary"
              :disabled="!canProgressToStep4"
              @click="createGiftcards"
              :loading="processing"
            >
              {{ $t('createGiftcards') }}
            </v-btn>
  
            <!--   <v-btn
              color="primary"
              :disabled="!canProgressToStep4"
              @click="createGiftcards"
              :loading="processing"
            >
              {{ $t('createGiftcards') }}
            </v-btn> -->
  
            <v-btn
              @click="e1 = 2"
              text
            >
              {{ $t('back') }}
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-card-text v-if="completed">
        {{ $t('giftcardsHaveBeenCreatedSuccessfully') }}
      </v-card-text>
    </v-card>
  </v-form>
</template>
  
  <script>
    export default {
      name: "NewFormOfPaymentDialog",
      props: {
        value: {
          type: Object,
          default: () => {}
        }, 
      },
      data() {
        return {
          e1: 1,
          quantity: 1,
          selectedItem: null,
          selectedAmount: null,
          remoteIdDisabled: false,
          processing: false,
          missingRequiredInput: false,
          customerName: null,
          customerPhone: null,
          customerSSN: null,
          expirationMonth: null,
          expirationYear: null,
          completed: false,
          customerNameFixed: false,
          customerPhoneFixed: false,
          customerEmailFixed: false
        }
      },
      computed: {
        months() {
          return [
            {
              key: '01',
              value: this.$t('january'),
            },
            {
              key: '02',
              value: this.$t('february'),
            },
            {
              key: '03',
              value: this.$t('march'),
            },
            {
              key: '04',
              value: this.$t('april'),
            },
            {
              key: '05',
              value: this.$t('may'),
            },
            {
              key: '06',
              value: this.$t('june'),
            },
            {
              key: '07',
              value: this.$t('july'),
            },
            {
              key: '08',
              value: this.$t('august'),
            },
            {
              key: '09',
              value: this.$t('september'),
            },
            {
              key: '10',
              value: this.$t('october'),
            },
            {
              key: '11',
              value: this.$t('november'),
            },
            {
              key: '12',
              value: this.$t('december'),
            }
          ]
        },
        years() {
          return ['2022', '2023', '2024', '2025', '2026', '2027']
        },
        quantities() {
          const quantities = []
  
          for(let i = 1; i < 200; i++) {
            quantities.push(i)
          }
  
          return quantities
        },
        canProgressToStep2() {
          return this.selectedItem !== null && this.selectedItem !== undefined
        },
        canProgressToStep3() {
          return this.customerName !== null && this.customerName !== undefined && this.customerPhone !== null && this.customerPhone !== undefined
        },
        canProgressToStep4() {
          return this.expirationMonth !== null && this.expirationMonth !== undefined && this.expirationYear !== null && this.expirationYear !== undefined
        },
        giftcardItems() {
          return this.$store.state.items.filter(item => item.type === 'giftcard')
        },
      },
      watch: {
        selectedItem(uuid) {
  
          const item = this.giftcardItems.find(item => item.uuid === uuid)
  
          this.selectedAmount = item.price.amount
        }
      },
      methods: {
        createGiftcards() {
          this.processing = true
          this.$store.dispatch('createMultipleGiftcards', {
            quantity: this.quantity,
            giftcard: {
              item: this.selectedItem,
              amount: parseFloat(this.selectedAmount),
              customerName: this.customerName,
              customerPhone: this.customerPhone,
              customerEmail: this.customerEmail,
              expiryYear: this.expirationYear,
              expiryMonth: this.expirationMonth
            }
          }).then(response => {
            this.completed = true
          }).finally(() => {
                      this.$store.commit('updateActionSuccess', {
                message: 'Success',
                subMessage: 'The giftcards have been issued and sent.'
              })
            this.processing = false
                      this.close()
          })
        },
        close() {
          this.$emit('closed')
        }
      }, 
      mounted() {
        if(typeof this.value.customerName !== 'undefined' && this.value.customerName !== null) {
            this.customerNameFixed = true 
            this.customerName = this.value.customerName
        } 
        if(typeof this.value.customerEmail !== 'undefined' &&  this.value.customerEmail !== null) {
            this.customerEmailFixed = true 
            this.customerEmail = this.value.customerEmail
        } 
        if(typeof this.value.customerPhone !== 'undefined' &&  this.value.customerPhone !== null) {
            this.customerPhoneFixed = true 
            this.customerPhone = this.value.customerPhone
        } 
        
      }
    }
  </script>
  
  <style scoped>
  
  </style>
  